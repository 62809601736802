



















































































import { Component } from "vue-property-decorator"
import { mixins } from "vue-class-component"
import { MixinFormConfig } from "@/mixins/form/MixinFormConfig"
@Component
export default class ComponentFormNavigationMessage extends mixins(
  MixinFormConfig,
) {}
