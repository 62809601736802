



















































































































































































































import { Component } from "vue-property-decorator"
import { mixins } from "vue-class-component"
import { ServicesProductAPI } from "@/plugins/api/servicesProductAPI"
import { MixinFormConfig } from "@/mixins/form/MixinFormConfig"
@Component
export default class ComponentFormStepResumoPedidoSucesso extends mixins(
  ServicesProductAPI,
  MixinFormConfig,
) {}
