import { Component, Vue } from "vue-property-decorator"
@Component
export class MixinFormConfig extends Vue {
  tipoServicoUsuario = {
    migracao: {
      name: "Migração",
      label: "Sou cliente pré da TIM e quero um plano Controle",
      enabled: ["controle", "express", "service"],
    },
    portabilidade: {
      name: "Portabilidade",
      label: "Sou de outra operadora e quero mudar para a TIM",
      enabled: ["controle", "service"],
    },
    ativacao: {
      name: "Ativação",
      label: "Quero uma nova linha com um número TIM",
      enabled: ["controle"],
    },
  }

  get formButtonsNavigator (): {
    [key: string]: {
      title: string;
      active: boolean;
    }
  } {
    const FORM_BUTTON_NAVIGATOR_TEMPLATE = {
      "formulario-de-pedido-cadastro": {
        title: "Cadastro",
        active: false,
      },
      "formulario-de-pedido-dados-pessoais": {
        title: "Dados pessoais",
        active: false,
      },
      "formulario-de-pedido-dados-de-pagamento": {
        title: "Dados de pagamento",
        active: false,
      },
    }

    Object.keys(FORM_BUTTON_NAVIGATOR_TEMPLATE).map(button => {
      if (this.formActiveRoute === button) {
        FORM_BUTTON_NAVIGATOR_TEMPLATE[button].active = true
      }
    })

    if (this.$vuetify.breakpoint.smAndDown) {
      return { [this.formActiveRoute || ""]: FORM_BUTTON_NAVIGATOR_TEMPLATE[this.formActiveRoute || ""] }
    } else {
      return FORM_BUTTON_NAVIGATOR_TEMPLATE
    }
  }

  get formActiveRoute (): string {
    return /formulario/.test(this.$route.name || "") ? (this.$route.name || "") : ""
  }
}
