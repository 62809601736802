






















































import { Component } from "vue-property-decorator"
import { mixins } from "vue-class-component"
import ComponentFormPanelDefaultLayout from "@/components/form/panel/ComponentFormPanelDefaultLayout.vue"
import ComponentFormPanelStepResumoPedidoSucesso from "@/components/form/panel/ComponentFormPanelStepResumoPedidoSucesso.vue"
import ComponentFormStepCadastroLayout from "@/components/form/step/ComponentFormStepCadastroLayout.vue"
import ComponentFormStepDadosPessoaisLayout from "@/components/form/step/ComponentFormStepDadosPessoaisLayout.vue"
import ComponentFormStepDadosPagamentoControle from "@/components/form/step/ComponentFormStepDadosPagamentoControle.vue"
import ComponentFormStepResumoPedidoSucesso from "@/components/form/step/ComponentFormStepResumoPedidoSucesso.vue"
import { MixinFormConfig } from "@/mixins/form/MixinFormConfig"
@Component({
  components: {
    ComponentFormPanelDefaultLayout,
    ComponentFormPanelStepResumoPedidoSucesso,
    ComponentFormStepCadastroLayout,
    ComponentFormStepDadosPessoaisLayout,
    ComponentFormStepDadosPagamentoControle,
    ComponentFormStepResumoPedidoSucesso,
  },
})
export default class ViewFormEntryData extends mixins(
  MixinFormConfig,
) {
  created (): void {
    setTimeout(() => {
      try {
        this.$vuetify.goTo(".goto--ViewFormEntryData")
      } catch { /** no-empty-catch */ }
    }, 100)
  }
}
