





































































































































































































import { Component } from "vue-property-decorator"
import { mixins } from "vue-class-component"
import { ServicesProductAPI } from "@/plugins/api/servicesProductAPI"
import { MixinFormConfig } from "@/mixins/form/MixinFormConfig"
import ComponentFormNavigationMessage from "@/components/form/content/ComponentFormNavigationMessage.vue"
@Component({
  components: {
    ComponentFormNavigationMessage,
  },
})
export default class ComponentFormStepDadosPagamentoControle extends mixins(
  ServicesProductAPI,
  MixinFormConfig,
) {}
