









































































































































































































import { Component } from "vue-property-decorator"
import { mixins } from "vue-class-component"
import { MixinFormat } from "@/mixins/format/MixinFormat"
import { ServicesProductAPI } from "@/plugins/api/servicesProductAPI"
@Component
export default class ComponentFormPanelStepResumoPedidoSucesso extends mixins(
  MixinFormat,
  ServicesProductAPI,
) {}
